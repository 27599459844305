import React from "react"
import Button from "../components/general/button/button"
import Layout from "../components/layout/Layout"
import BenefitsSection from "../components/landing/benefits"
import ServicesSection from "../components/landing/services"
import ContactForm from "../components/general/contactForm/kontaktuForma"
import Kontaktai from "../components/general/contacts/Kontaktai"
import { jsx, css } from "@emotion/core"
import { Helmet } from "react-helmet"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Patikimas lengvųjų automobilių servisas Kaune, Panemunėje.
        </title>
        <meta
          name="description"
          content="Kokybiškos automobilių remonto paslaugos: variklio remontas, variklio diagnostika, važiuoklės remontas. Taip pat, pas mus atliekamas ir padangų keitimas, padangų remontas, ratų montavimas, ratų balansavimas, tepalų keitimas."
        ></meta>
      </Helmet>
      <header css={headerStyles}>
        <div css={pageTitle}>
          <div css={titleLeadIn}>Vaidoto183</div>
          <h1 css={heroTitle}>Automobilių servisas Kaune</h1>
        </div>
      </header>
      <BenefitsSection />
      <ServicesSection />
      <ContactForm />

      <Kontaktai showTitle={true} />
    </Layout>
  )
}

const headerStyles = css`
  background-image: url("https://ik.imagekit.io/jaesjef6q7/general-assets/tr:w-1500/GBIT4279_1fm92QZC2rb5.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
`

const heroTitle = css`
  margin-top: 0;
  background-color: #212529e3;
  margin: 0 15px;
  font-size: 43px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 50px;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  text-align: center;
  color: rgb(255, 255, 255);
  @media (min-width: 638px) {
    font-size: 75px;
    padding: 15px;
    line-height: 75px;
  }
`

const titleLeadIn = css`
  font-size: 40px;
  font-style: italic;
  line-height: 40px;
  margin-bottom: 25px;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding: 10px;
  display: inline-block;
  background-color: #feb836;
  color: #fff;
`

const pageTitle = css`
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
  color: #fff;
  padding-top: 100px;
  padding-bottom: 80px;
  font-weight: 75px;

  @media (min-width: 638px) {
    font-size: 75px;
    padding-top: 230px;
    padding-bottom: 200px;
  }
`
